import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Layout } from "src/components/Layout"
import { Container } from "src/components/Container"
import { Helmet } from "src/components/Helmet"

const NotFoundPage = () => (
  <>
    <Helmet title="Что-то пошло не так!"></Helmet>
    <Layout>
      <Container>
        <Inner>
          <Heading>
            ОЙ!
            <span role="img" aria-label="monkey">
              🙊
            </span>
          </Heading>
          <HelpText>
            Такой страницы у нас нет. <br></br>
            Проверьте адрес или <Link to="/">начните с главной</Link>
          </HelpText>
        </Inner>
      </Container>
    </Layout>
  </>
)

export default NotFoundPage

const Heading = styled.h1`
  font-size: 10vmin;
`

const Inner = styled.div`
  padding-top: 88px;
  padding-bottom: 88px;

  text-align: center;
`

const HelpText = styled.p`
  font-size: 4vmin;
  line-height: 140%;
  color: #828c9f;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
